.blog_main_div {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.blog_sub_div {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 5%;
}
 .blog_sub_div h1 {
  font-family: Century GothicBold;
  font-weight: 600;
  font-size: 40px;
  color: #484848;
}
.heading_p {
  width: 80%;
  font-family: Century "GothicBold";
  color: #484848;
  text-align: center;
  font-weight: 400;
  font-size: 20px;
}
.blog_content_div {
  width: 80%;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}
.blog_1 {
  width: 30%;
  margin-bottom: 5%;
}
.blog_1_img {
  width: 100%;
  position: relative;
}
.blog_1_img img {
  width: 100%;
  border-radius: 10px;
}
.blog_date {
  font-family: Century "GothicBold" !important;
  font-weight: 400;
  font-size: 12px;
  color: #47b45f;
  margin: 1% 0;
}
.blog_1 h4 {
  font-family: Century GothicBold;
  font-weight: 600;
  font-size: 18px;
}
.blog_1_p {
  font-family: Century Gothic;
  font-size: 14px;
  font-weight: 400;
}
.blog_icon_div {
  display: flex;
  gap: 2%;
  font-size: 12px;
  justify-content: flex-start;
}
.icon_1 {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 70%;
  gap: 5%;
}
.icon_1 p {
  margin-bottom: 0;
}
.blogger_info {
  position: absolute;
  bottom: 10px;
  width: 30px;
  height: 30px;
  left: 45px;
  display: flex;
  justify-content: center;
  gap: 7px;
  align-items: center;
}
.blogger_info img {
  width: 100%;
}
.blogger_info p {
  color: white;
  margin-bottom: 0;
}
.blog_conten_wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  /* margin: 8% 0; */
  margin-top: 8%;
}


@media only screen and (max-width:1380px){
  .blog_sub_div h1{
    font-size: 33px;
  }
  .heading_p {
    font-size: 16px;
  }
  .blog_1 h4{
    font-size: 16px;
  }
}
@media only screen and (max-width:1024px){
  .blog_content_div{
    width: 90%;
  } 
  .heading_p{
    width: 90%;
  } 
}
@media only screen and (max-width:768px){
  .blog_1{
    width: 46%;
  }
  .blog_conten_wrapper{
    justify-content: center;
    gap: 5%;
  }
  .heading_p{
    font-size: 14px;
  }
  .blog_1 h4 {
    font-size: 14px;
}
.blog_1_p{
  font-size: 12px;
}
.icon_1 {
  width: 70%;
}
.blog_sub_div h1 {
  font-size: 28px;
}
.blog_sub_div {
  padding: 0;
}
}
@media only screen and (max-width:450px){
  .blog_1{
    width: 70%;
  }
  .blog_sub_div {
    padding: 5% 0;
}
.blog_sub_div h1 {
  font-size: 24px;
}
.heading_p {
  font-size: 12px;
}
}