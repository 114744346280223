.box {
  background: linear-gradient(
    129deg,
    #e3e3e37a 11.66%,
    rgb(227 227 227 / 0%) 62.34%
  );
  font-family: "CenturyGothic";
  font-size: 36px;
  font-weight: 700;
  line-height: 44px;
  text-align: left;
  position: relative;
}
.joinSection_wrapper_main_div{
  display: flex;
  justify-content: center;
}

strong {
  color: #47b45f;
  font-family: "GothicBold";
}
.box-content {
  margin-top: 13%;
  margin-left: 12%;
  width: 94% !important;
  font-family: "CenturyGothic";
  font-weight: 600;
  font-size: 40px;
  line-height: 51.5px;
}
.joinsection_btns_div {
  display: flex;
  align-items: center;
  margin-left: 13%;
  gap: 4%;
}
.joinsection_btns_div button {
  border: none;
  outline: none;
}

.box-buttons .img1 {
  padding: 0 !important;
}

.table-image {
  position: absolute;
  top: 56%;
  border-radius: 35px;
  width: 355px !important;
}

.box-image {
  position: relative;
  height: 562px;
  width: 100%;
  object-fit: contain;
  border-radius: 0px;
}

@media only screen and (max-width: 1400px) {
  .box-content {
    font-size: 37px;
  }
}
@media screen and (max-width:1024px){
  .box-content{
    font-size: 26px;
  }
  .box-image{
    height: 450px;
  }
  .table-image{
    top: 54%;
    width: 295px !important;
  }
}
@media screen and (max-width:992px) {
  .inner_content_wrapper_div_joinSection{
    width: 50%;
  }
}
@media screen and (max-width:768px){
  .box-content {
    font-size: 28px;
    width: 80% !important;
    margin-left: 10%;
    margin-top: 7%;
    padding: 2% 0;
}
.joinsection_btns_div{
  margin-bottom: 10%;
  margin-left: 0;
  justify-content: center;
}
.table-image{
  display: none;
}
.box-image {
width: 90%;
height: 392px;
}
.inner_content_wrapper_div_joinSection{
  width: 100%;
  text-align: center;
}
.joinSection_wrapper_main_div{
  flex-direction: column;
}
}
@media only screen and (max-width: 600px) {
  .table-image {
    display: none;
  }

  .box-image {
    display: none;
  }
  .box-content {
    font-size: 20px;
    line-height: 26px;
  }
  .box-buttons {
    text-align: center;
  }
  .img1 {
    padding: 0px !important;
  }
}
