.nav {
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: 22px;
  letter-spacing: 0em;
  text-align: left;
}
.navAbout {
  background-color: #ffffff;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: 22px;
  letter-spacing: 0em;
  text-align: left;
}
.navbar {
  background-color: white;
  font-family: "CenturyGothic";
  z-index: 999;
  height: 100px;
  width: 100%;
  display: flex !important;
  flex-direction: row !important;
  justify-content: center !important;
}
.navbar_wrapper {
  display: flex;
  justify-content: space-between;
  width: 90%;
  align-items: center;
}

.navbar li {
  display: inline-block !important;
}

.logo {
  height: 50px;
  
}
.navbar_ul {
  width: 430px;
}
#menu {
  display: flex;
  justify-content: space-between !important;
  font-family: "GothicBold";
  font-weight: 200;
  padding: 0;
  width: 100%;
}

.nav-item {
  text-decoration: none;
  color: #484848;
}
.nav-item:hover {
  color: #47b45f;
}
.active-nav-item {
  color: #47b45f;
}

.button {
  background-color: #47b45f;
  border-radius: 20px;
  margin-right: 20px;
  padding: 8px 30px;
  border: none;
  box-shadow: 2px 2px 10px #47b45f;
}
.button1 {
  border-radius: 20px;
  margin-right: 20px;
  padding: 8px 30px;
}.mobile_navigation{
  display: none;
}
@media only screen and (max-width: 1300px) {
  .navbar_wrapper {
    width: 90%; 
  }

  .nav-item {
    margin-right: 20px; 
  }
}

@media only screen and (max-width: 376px) {
 
  .btns {
    position: relative;
    left: 45%;
    bottom: 50%;
  }
  .button {
    padding: 3px 12px;
    border: none;
    box-shadow: 2px 2px 10px #47b45f;
  }
  .button1 {
    padding: 3px 13px;

    margin-right: 10px;
  }
  .nav {
    height: 80px;
  }
}
@media only screen and (max-width: 1050px) {
  .button {
    font-size: 12px !important;
  }
  .button1 {
    font-size: 12px !important;
  }
}
@media screen and (max-width:1024px){
  .nav-item{
    font-size: 14px;
  }
  #menu{
    justify-content: flex-end !important;
    gap: 3%;
  }
  .button1{
    padding: 5px 20px;
  } 
  .button{
    padding: 7px 20px;
  } 
}
@media screen and (max-width:930px){
  .mobile_navigation {
    display: block;
    position: relative;
}
.navbar_wrapper{
  display: none;
}
.mobile_navigation{
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 90%;
}
#menu_mobile{
  display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}
.navbar_wrapper_mobile{
  position: absolute;
  bottom: -205px;
  background-color: #F9F9F9;
width: 100%;
padding-bottom: 2%;
}
.nav-item_mobile{
  color: black;
  font-size: 14px;
  text-decoration: none;
}
.nav-item_mobile:active,
.nav-item_mobile:focus {
  color: #47b45f;
}
.btns_mobile{
  display: flex;
  justify-content: center;
  align-items: center;
}
}
@media only screen and (max-width: 450px) {
 

  .navbar_wrapper_mobile{
    bottom: -192px;
  }
}

