.vocal {
  width: 100%;
  height: 228.64px;
  margin-top: 6%;
  margin-bottom: 4%;
}
@media only screen and (max-width: 600px) {
  .vocal {
    width: 100%;
    height: 128.64px;
  }
}

