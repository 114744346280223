.blogdetails_main_div {
  display: flex;
  justify-content: center;
}
.blogdetails_sub_div {
  width: 80%;
}
.blogdetails_content_div {
  margin: 3% 0;
}
.blogdetails_content_div h6 {
  font-family: Century Gothic;
  font-weight: 600;
  font-size: 16px;
  color: #47b45f;
}
.blogdetails_content_div h3 {
  font-family: Century Gothic;
  font-weight: 600;
  font-size: 32px;
  color: #484848;
}
.blogdetails_content_div p {
  font-family: Century Gothic;
  font-weight: 400;
  font-size: 16px;
  /* color: #484848; */
}
.blogdetails_blogger_info {
  width: 50%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 10px;
}
.blogdetails_blogger_info p {
  margin-bottom: 0;
  color: #484848;
  font-family: Century Gothic;
  font-size: 16px;
  font-weight: 600;
}
.blogdetails_blogger_info_main_div {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 1% 0;
}
.blogdetails_icon_div {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  width: 50%;
}
.blogdetails_icon_1 {
  width: 42%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 5px;
}
.blogdetails_icon_1 h5 {
  font-family: Century Gothic;
  font-weight: 600;
  font-size: 14px;
  color: #484848;
  margin-bottom: 0;
}
.blog_details_img_div {
  width: 100%;
  margin: 2% 0;
}
.blog_details_img_div img {
  width: 100%;
  height: auto;
}
.blog_details_bottom_content_div {
  margin: 2% 0;
}
.blog_details_bottom_content_div h4 {
  font-family: Century Gothic;
  font-weight: 600;
  font-size: 18px;
  color: #484848;
}
.blog_details_bottom_content_div p {
  font-family: Century Gothic;
  font-weight: 400;
  font-size: 20px;
}
.blog_details_content {
  width: 100%;
}
@media only screen and (max-width:1380px){
  .blog_details_bottom_content_div p{
    font-size: 16px;
  }
}
@media only screen and (max-width:1024px){
  .blogdetails_content_div h3 {
  font-size: 24px;
  }
}
@media only screen and (max-width:768px){
  .blogdetails_content_div h3 {
    font-size: 20px;
}
.blogdetails_content_div p{
  font-size: 14px;
}
.blogdetails_icon_1{
  width: 90%;
}
}
@media only screen and (max-width:450px){
  .blogdetails_content_div{
    margin: 8% 0;
  } 
}