.header_main_div {
  background-color: #f2fbf6;
  width: 100%;
  padding: 5% 0;
  display: flex;
  justify-content: center;
  align-items: center;
}

.header_sub_div {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 90%;
}

.header_left_div {
  width: 45%;
}

.header_right_div {
  width: 55%;
  padding: 0 5%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.header_left_div h1 {
  font-size: 40px;
  font-weight: 600;
  font-family: Century Gothic;
  color: #484848;
  margin: 2% 0;
}

.header_left_div p {
  color: #484848;
  font-size: 18px;
  font-weight: 400;
  font-family: 'CenturyGothic';
  margin: 2% 0;
}

.header_left_div span {
  color: #47b45f;
}

.header_left_div button {
  background-color: #47b45f;
  font-family: Century Gothic;
  font-size: 18px;
  border: none;
  outline: none;
  color: white;
  width: 200px;
  padding: 2% 0;
  border-radius: 40px;
  margin: 2% 0;
}

.qr_scan_img_div {
  width: 30%;
}

.qr_scan_img_div img {
  width: 100%;
}

.qr_scan_main_div {
  width: 70%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 4% 0;
}

.qr_scan_main_div h3 {
  width: 67%;
  color: #484848;
  font-size: 18px;
  font-weight: 600;
  font-family: Century Gothic;
}

.header_right_left-div {
  margin-top: 38%;
  position: relative;
  background-color: white;
  border-radius: 20px;
  width: 36%;
  display: flex;
  justify-content: flex-start;
  padding: 2% 2%;
  flex-direction: column;
}

.header_right_left-div p {
  margin: 0;
  font-family: Century Gothic;
  font-weight: 600;
  font-size: 15px;
}

.header_right_right-div {
  margin-bottom: 53%;
  position: relative;
  background-color: white;
  border-radius: 20px;
  padding: 2% 0;
  width: 10%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.header_right_center-div {
  width: 50%;
}

.header_icon_main_div {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 5%;
  margin: 4% 0;
}

.mobile_img {
  width: 100%;
}

.mobile_img img {
  width: 100%;
}

.header_button {
  z-index: 333;
  background: var(--lesson-12-player-gradient,
      linear-gradient(90deg, #00a9d3 0%, rgba(0, 169, 211, 0.4) 100%),
      #f9f9f9);
  padding: 30%;
  border-radius: 50px;
  border: none;
  margin: 5% 0;
}

.header_button:focus {
  border: solid 2px black;
}

.header_button_1 {
  background: var(--Lesson-10---Player-Grad,
      linear-gradient(90deg, #cd1f26 0%, rgba(205, 31, 38, 0.4) 100%),
      #f9f9f9);
}

.header_button_2 {
  background: var(--Lesson-11---Player-Grad,
      linear-gradient(90deg, #6fbe44 0%, rgba(111, 190, 68, 0.4) 100%),
      #f9f9f9);
}

.header_button_3 {
  background: var(--Lesson-15---Player-Grad,
      linear-gradient(90deg, #6e5c6f 0%, rgba(110, 92, 111, 0.4) 100%),
      #f9f9f9);
}

.colorarrow_img {
  position: absolute;
  width: 250px;
  top: -23px;
  left: -130px;
}

.textarrow_img {
  position: absolute;
  top: 88px;
  right: -18px;
}

.colorarrow_img img {
  width: 100%;
}

@media screen and (max-width: 1380px) {
  .header_left_div h1 {
    font-size: 33px;
  }

  .header_left_div p {
    font-size: 16px;
  }

  .header_left_div button {
    width: 160px;
    font-size: 16px;
  }
  .homepage_contact_cotent h4{
font-size: 33px ;
  }
}

@media screen and (max-width:1024px) {
  .header_right_div {
    padding: 0 4%;
  }

  .mobile_img {
    width: 90% !important;
  }

  .header_right_left-div {

    width: 33%;
  }

  .header_right_center-div {
    display: flex;
    justify-content: center;
  }

  .homepage_contact h4 {
    font-size: 37px;
  }

  .homepage_contact_cotent h6 {
    line-height: 22px;
  }

  .homepage_form {
    padding: 0.4% 15px;
  }

  .box-content {
    font-size: 27px;
  }
}

@media screen and (max-width:800px) {
  .header_left_div h1 {
    font-size: 28px;
  }

  .header_left_div p {
    font-size: 14px;
  }

  .header_left_div button {
    width: 160px;
    font-size: 14px;
  }

  .qr_scan_main_div h3 {
    font-size: 17px;
  }

  .header_icon_main_div img {
    width: 22px;
    height: 22px;
  }

  .header_icon_main_div p {
    font-size: 14px;
  }

  .textarrow_img {
    top: 53px;
  }

  .colorarrow_img {
    top: -67px;
  }
}

@media screen and (max-width:768px) {
  .header_sub_div {
    flex-direction: column;
  }

  .header_left_div {
    width: 100%;
  }

  .qr_scan_img_div {
    width: 25%;
  }

  .qr_scan_main_div {
    width: 60%;
    justify-content: flex-start;
    gap: 3%;
  }

  .header_right_div {
    width: 65%;
  }
  .homepage_contact_cotent {
    width: 80% !important;
  }
}

@media screen and (max-width:450px) {

  .header_left_div h1 {
    font-size: 26px;
  }
  .header_left_div p{
    margin: 4% 0;
  }
  .qr_scan_main_div {
    width: 80%;
  }
  .header_right_div {
    width: 100% !important;
}
.homepage_contact_cotent {
  width: 100% !important;
}
}