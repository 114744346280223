.container {
  font-family: "CenturyGothic";
  position: relative;
  z-index: 5;
  color: #484848;

  position: relative;
}
.l-container {
  font-family: "CenturyGothic";
  position: relative;
  z-index: 5;
  position: relative;
  width: 100%;
  top: 0;
}
.r-vector {
  position: absolute;
  height: 100%;
  width: 100%;
  object-fit: cover;
  top: 0;
  left: 0;
}
.login-end {
  font-family: "GothicBold";
  color: #484848;
  font-weight: 200;
  margin-top: 28%;
  text-align: right;
  margin-right: 17%;
}

.login-end a {
  text-decoration: none;
}

Label {
  font-family: "GothicBold";
}

.react-tel-input .flag-dropdown {
  border-radius: 27px !important;
  margin-left: 20px !important;
  background-color: white !important;
  border: none !important;
}

@media only screen and (max-width: 600px) {
  .r-vector {
    display: none;
  }

  .login_head {
    background-color: #00dcf09a;
  }
  .l-container {
    background-color: #00dcf09a;
    height: 85vh;
    object-fit: contain;
  }
 
  .login-end {
    font-family: "GothicBold";
    color: #484848;
    font-weight: 200;
    font-size: 10px;
    margin-top: 28%;
    text-align: right;
  }
}

