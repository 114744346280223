.footer_1st_div {
  width: 30%;
  position: relative;
  z-index: 44444;
}

.f-text {
  margin-left: 28%;
}


.footer_2nd_section {
  width: 30%;
  display: flex;
  flex-direction: column;
}

.btm_to_top_btn {
  width: 5%;
}

.div3 {
  width: 30%;
}

.div3 h6 {
  font-size: 0.9rem;
}

.footer {
  font-family: "CenturyGothic";
  background-repeat: no-repeat !important;
  background: url("../assets/images/Background.svg");
  background-size: 100% 100%;

  width: 100%;
  overflow-x: hidden !important;
}

.footer .c3 {
  width: 100% !important;
  bottom: 0;
}

.end {
  padding-top: 2%;
  margin-left: 7%;
  padding-bottom: 1%;
  width: 92%;
}

.s1 {
  border-bottom: 1px solid #e3e3e3;
  padding-top: 235px;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.f-nav {
  color: #666666;
  font-size: 25px;
  margin-top: 12%;
  display: flex;
  justify-content: flex-start;

}

.icons {
  margin-top: 30%;
}

.icons img {
  margin-right: 2%;
}

#f-menu {
  padding: 0;
}

#f-menu li {
  margin-bottom: 4%;
  font-size: 15px;
  font-style: normal;
  font-weight: 700;
  list-style: none;
  padding-left: 0 !important;
}

.link {
  color: rgb(22, 47, 129);
  text-decoration: none;
  font-family: "GothicBold";
}

.f-text h6 {
  display: inline;
  margin-right: 2%;
  font-weight: 600;
  font-size: 14px;
}

.box-buttons2 {
  display: flex;
  align-items: center;
  gap: 5%;
}

.box-buttons2 img {
  width: 100%;
}

.up-arrow {
  margin-top: -100px;
}

.nav-item1 {
  text-decoration: none;
  color: #484848;
  margin-right: 40px;
}

.nav-item1:hover {
  color: #47b45f;
}

@media only screen and (max-width: 1400px) {
  .f-nav {
    font-size: 20px;
  }

  .nav-item1 {
    font-size: 15px;
  }
}

@media only screen and (max-width: 1200px) {
  .footer_2nd_section {
    width: 24%;
  }

  .btm_to_top_btn {
    width: 10%;
  }
}

@media only screen and (max-width: 768px) {
  .f-text {
    margin-top: 5%;
    display: flex;
    flex-direction: column;
  }

  .box-buttons2 img {
    width: 80%;
  }

  .end h6 {
    font-size: 12px;
  }

  .up-ardiv {
    width: 60%;
  }

  .f-text h6 {
    font-size: 12px;
  }

  .box-buttons2 {
    gap: 0;
  }
}

@media only screen and (max-width: 550px) {
  .icons img {
    width: 70%;
  }

  .icons {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .f-nav {
    font-size: 14px;
  }

  .nav-item1 {
    font-size: 12px;
  }

  .div3 h6 {
    font-size: 0.8rem;
  }

  .end {
    padding-top: 2%;
    margin-left: 0;
    padding-bottom: 1%;
    width: 100%;
    text-align: center;
  }

  .div3 {
    margin-bottom: 8%;
  }
}

@media only screen and (max-width: 450px) {
  .vocal {
    margin-bottom: -45%;
  }

  .box-buttons2 {
    flex-direction: column;
  }

  .link {
    margin-bottom: 2%;
  }

  .f-text h6 {
    font-size: 10px;
  }
}