.aboutImg {
  position: relative;
  width: 70%;
  left: 246px;
  /* top: 1px; */
  top: -88.907227px;
  /* bottom: 21%; */
}
.aboutText {
  font-family: "CenturyGothic";
  font-size: 19px;
  line-height: 29px;
  letter-spacing: 0px;
  text-align: left;
  color: #969696;
}
.aboutHead {
  font-family: "GothicBold";

  font-size: 86px;
  font-style: normal;
  font-weight: 700;
  line-height: 118px;
  letter-spacing: 0px;
  text-align: left;
  color: #47b45f;
}
.aboutHead span {
  font-family: "GothicBold";

  font-size: 86px;
  font-style: normal;
  font-weight: 700;
  line-height: 118px;
  letter-spacing: 0px;
  text-align: left;
  color: #484848;
}
.firstCol {
  margin-left: 5%;
  width: 50%;
  margin-top: 5%;
  display: flex;
  justify-content: center;
  flex-direction: column;
}
.firstCol p {
  font-family: Century Gothic;
  font-size: 18px;
  font-weight: 400;
}
.firstCol button {
  font-family: Century Gothic;
  font-weight: 600;
  color: white;
  padding: 1.5%;
  background-color: #00b666;
  width: 40%;
  font-size: 18px;
  border-radius: 20px;
  border: none;
  outline: none;
  margin: 4% 0;
}
.about_books_section {
  background-color: #f5fbf7;
  width: 100%;
  padding: 5% 0;
  display: flex;
  justify-content: center;
}
.about_book_main_div {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 90%;
}
.book_section_left {
  width: 45%;
}
.book_section_left h2 {
  font-family: Century Gothic;
  font-size: 40px;
  color: #484848;
  font-weight: 600;
}
.book_section_left p {
  font-family: Century Gothic;
  font-weight: 400;
  font-size: 18px;
}
.hero_section_image {
  width: 50%;
}
.hero_section_wrapper_about {
  display: flex;
  justify-content: space-between;
}
.book_section_left button {
  width: 40%;
  padding: 2%;
  background-color: #00b666;
  border: none;
  outline: none;
  color: white;
  border-radius: 20px;
  font-family: Century Gothic;
  font-weight: 600;
  font-size: 16px;
  text-align: center;
}
.book_section_right {
  width: 50%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
}
.about_book1 {
  margin-bottom: 2%;
  background-color: #fff;
  border-radius: 10px;
  padding: 6px 0;
  width: 32%;
  display: flex;
  justify-content: center;
  flex-direction: column;
}
.about_book1_img_div {
  width: 100%;
}
.about_book1_img_div img {
  width: 100%;
  border-radius: 10px;
}
.about_book1 h3 {
  font-family: Century Gothic;
  font-size: 14px;
  padding: 0 11px;
  font-weight: 600;
}
.about_book1 p {
  font-family: Century Gothic;
  font-size: 12px;
  padding: 0 11px;
  margin-bottom: 0;
  font-weight: 400;
}
.mission_main_div {
  width: 100%;
  display: flex;
  justify-content: center;
  padding: 3% 0;
}
.mission_sub_div {
  width: 80%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.mission_left_div {
  width: 45%;
  display: flex;
  justify-content: center;
}
.mission_right_div {
  color: white;
  width: 50%;
}
.mission_img_div {
  width: 90%;
}
.mission_img_div img {
  width: 100%;
}
.mission_right_div h2 {
  color: #484848;
  font-family: Century Gothic;
  font-weight: 600;
  font-size: 40px;
}
.mission_right_div span {
  color: #00b666;
}
.mission_right_div p {
  font-family: Century Gothic;
  font-size: 18px;
  font-weight: 400;
  color: #484848;
  margin: 3% 0;
}
.mission_right_div button {
  font-family: Century Gothic;
  font-weight: 600;
  color: white;
  padding: 1.5%;
  background-color: #00b666;
  width: 40%;
  font-size: 18px;
  border-radius: 20px;
  border: none;
  outline: none;
}
.founder_main_div {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin: 5% 0;
}
.founder_sub_div {
  width: 80%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.founder_sub_div h1 {
  text-align: center;
  color: #484848;
  font-family: Century Gothic;
  font-weight: 600;
  font-size: 40px;
}
.founder_sub_div span {
  color: #00b666;
}
.founder_sub_div p {
  font-family: Century Gothic;
  font-size: 18px;
  font-weight: 400;
  color: #484848;
  width: 70%;
  text-align: center;
  margin-bottom: 5%;
}
.about_founders {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.founder_info {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 30%;
}
.founder_info img {
  width: 100%;
}
.founder_info h4 {
  color: #484848;
  font-family: Century Gothic;
  font-size: 24px;
  font-weight: 700;
  text-align: center;
  margin: 2% 0;
}
.founder_info h6 {
  color: #00b666;
  font-family: Century Gothic;
  font-size: 16px;
  font-weight: 400;
  text-align: center;
}
.message_ceo_main_div {
  background-color: #47b45f;
  width: 100%;
  display: flex;
  justify-content: center;
  padding: 5% 0;
}
.message_ceo_left_div {
  position: relative;
  width: 45%;
}

.message_ceo_sub_div {
  width: 80%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.message_ceo_left_div h1 {
  font-family: Century Gothic;
  font-weight: 600;
  font-size: 42px;
  color: #f9f9f9;
}
.message_ceo_left_div p {
  font-family: Century Gothic;
  font-weight: 400;
  font-size: 18px;
  color: #f9f9f9;
}
.message_ceo_left_div h5 {
  font-family: Century Gothic;
  font-weight: 400;
  font-size: 24px;
  color: #f9f9f9;
  position: relative;
}
.message_ceo_left_div span {
  font-family: Century Gothic;
  font-weight: 400;
  font-size: 100px;
  color: #f9f9f9;
  position: absolute;
  top: -50px;
  left: -49px;
}
.bottom_span{
  top: 175px !important;
  left: 165px !important;
}
.partner_main_div {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.partner_sub_div {
  width: 80%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 6% 0;
}
.partner_left_div span {
  color: #00b666;
}
.partner_left_div h1 {
  color: #484848;
  font-family: "Century Gothic";
  font-weight: 600;
  font-size: 42px;
}
.partner_left_div p {
  color: #484848;
  font-family: "Century Gothic";
  font-weight: 400;
  font-size: 18px;
}
.partner_left_div {
  width: 45%;
}
.parter_logo_list {
  width: 80%;
}
.partner_right_div img {
  width: 100%;
}
.partner_right_div {
  width: 45%;
  display: flex;
  justify-content: flex-end;
}
.mapBox iframe {
  width: 100%;
  height: 60vh;
}
@media only screen and (max-width:1600px){
  .bottom_span {
    top: 170px !important;
    left: 395px !important;
}
}
@media only screen and (max-width: 1400px) {
  .aboutImg {
    position: relative;
    width: 100%;
    left: 6px;
    top: -88.907227px;
  }
  .aboutHead {
    font-size: 66px;
  }
  .aboutHead span {
    font-size: 66px;
  }
}
@media only screen and (max-width: 1024px) {
  .partner_sub_div{
    width: 90%;
  }
  .message_ceo_sub_div{
    width: 90%;
  } 
  .partner_left_div p {
  font-size: 16px;
  }
  .partner_left_div h1{
    font-size: 32px;
  }
  .message_ceo_left_div h1 {
    margin: 0;
  font-size: 32px;
  }
  .message_ceo_left_div h5{
    font-size: 20px;
  }
  .founder_sub_div p {
    width: 100%;
    font-size: 15px;
  }
  .aboutHead {
    font-size: 46px;
    margin: 0;
  }
  .aboutHead span {
    font-size: 46px;
    margin: 0;
  }
  .firstCol p {
    font-size: 15px;
  }
  .firstCol button {
    font-size: 15px;
    width: 35%;
  }
  .about_book_main_div {
    flex-direction: column;
  }
  .book_section_left {
    width: 100%;
  }
  .book_section_right {
    width: 100%;
    margin-top: 5%;
  }
  .book_section_left p {
    font-size: 15px;
  }
  .book_section_left button {
    width: 25%;
    padding: 1%;
  }
  .mission_sub_div {
    width: 90%;
  }
  .mission_right_div p {
    font-size: 15px;
  }
  .founder_sub_div{
    width: 90%;
  } 
}
@media only screen and (max-width: 830px) {
  .mission_right_div h2 {
    font-size: 33px;
  }
}
@media only screen and (max-width: 768px) {
  .partner_right_div{
    width: 100%;
    justify-content: center;
    margin: 4% 0;
  } 
  .partner_sub_div{
    flex-direction: column;
  }
  .partner_left_div{
    width: 100%;
  } 
  .message_ceo_left_div{
width: 100%;
  } 
  .message_ceo_sub_div{
    width: 90%;
    flex-direction: column;
  } 
  .founder_info h4 {
    font-size: 18px;
  }
  .founder_info h6 {
    font-size: 14px;
  }
  .founder_sub_div h1 {
    font-size: 33px;
  }
  .mission_right_div button {
    padding: 1%;
    width: 30%;
    font-size: 15px;
  }
  .mission_left_div {
    display: none;
  }
  .mission_right_div {
    width: 100%;
  }
  .mission_sub_div {
    flex-direction: column-reverse;
  }
  .book_section_left h2 {
    font-size: 30px;
  }
  .aboutHead {
    font-size: 35px !important;
  }
  .aboutHead span {
    font-size: 35px;
  }
  .hero_section_image {
    display: none;
  }
  .firstCol {
    width: 90%;
  }
  .aboutText {
    font-size: 14px;
    line-height: 21px;
  }
  .aboutHead {
    font-family: "GothicBold";
    font-size: 56px;
  }
}
@media only screen and (max-width: 600px) {
  .about_book1 {
    width: 45%;
  }
  .book_section_left h2 {
    font-size: 25px;
  }
  .aboutHead {
    font-size: 30px !important;
  }
  .aboutHead span {
    font-size: 30px;
  }
  .book_section_left button {
    width: 35%;
  }
  .message_ceo_left_div h5 {
    font-size: 16px;
  }

}
@media only screen and (max-width: 450px) {
  .founder_main_div{
    margin: 10% 0;
  }
  .mission_right_div h2 {
    font-size: 30px;
  }
  .book_section_left h2 {
    font-size: 20px;
  }
  .book_section_left p {
    font-size: 14px;
  }
  .book_section_right {
    justify-content: center;
  }
  .about_book1 {
    width: 70%;
    margin: 5% 0;
  }
  .about_books_section {
    padding: 15% 0;
  }
  .founder_info h4 {
    font-size: 15px;
  }
  .founder_info h6 {
    font-size: 12px;
  }
}

