.books_main_div {
  background-color: #f9f9f9;
  margin-bottom: 0;
  width: 100%;
  padding: 5% 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.books_main_div h1 {
  text-align: center;
  font-family: Century Gothic;
  font-weight: 600;
  font-size: 36px;
  line-height: 44.14px;
  margin-bottom: 3%;
}

.book_one {
  background-color: #fff;
  border-radius: 30px;
  width: 30%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-bottom: 4%;
  position: relative;
}

.books_sub_div {
  width: 80%;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}

.book_img {
  width: 100%;
  display: flex;
  justify-content: center;
  margin: 5% 0;
}

.book_img img {
  width: 90%;
  box-shadow: 0px 7px 20px 0px rgba(72, 72, 72, 0.1);
}

.books_sub_div h5 {
  font-family: Century Gothic;
  font-weight: 600;
  line-height: 24.52px;
  color: #484848;
  font-size: 18px;
  padding: 0 5%;
}

.books_sub_div p {
  font-family: Century Gothic;
  font-weight: 400;
  font-size: 15px;
  line-height: 23px;
  padding: 1% 5%;
  margin-bottom: 5px;
}

.book_one button {
  margin: 0 5%;

  background-color: #47b45f;
  width: 90%;
  padding: 3%;
  border-radius: 30px;
  border: none;
  outline: none;
  color: white;
  font-family: Century Gothic !important;
  font-size: 16px;
  font-weight: 600;
}

.price_btn {
  background-color: #ffffff !important;
  width: 26% !important;
  margin-left: 65% !important;
  position: absolute;
  top: 22px;
  left: -1%;
  color: black !important;
  font-family: Century "GothicBold" !important;
  font-size: 17px !important;
  font-family: Century Gothic !important;
}

.book_one h6 {
  font-family: Century Gothic;
  font-size: 16px;
  text-align: center;
  padding: 2% 0;
}

.homepage_contact {
  background: var(--Lesson-11---Player-Grad,
      linear-gradient(90deg, #6fbe44 0%, rgba(111, 190, 68, 0.4) 100%),
      #f9f9f9);
  width: 80%;
  padding: 3%;
  position: relative;
  border-radius: 20px;
}

.homepage_contact h4 {
  font-family: Century Gothic;
  font-weight: 600;
  font-size: 42px;
  line-height: 51.5px;
  color: #ffff;
}

.homepage_contact_cotent h6 {
  font-family: Century Gothic;
  font-weight: 400;
  font-size: 16px;
  line-height: 30.53px;
  color: white;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.homepage_contact_cotent {
  width: 60%;
}

.homepage_contact_cotent p {
  font-family: Century Gothic;
  font-weight: 400;
  font-size: 20px;
  line-height: 24px;

  color: white;
}

.homepage_form {
  background-color: white;
  width: 70%;
  padding: 0 1%;
  display: flex;
  align-items: center;
  border-radius: 43px;
  padding: 1% 15px;
  justify-content: space-between;
}

.homepage_form input {
  border: none;
  outline: none;
  padding: 2% 1%;
  width: 60%;
  font-family: Century Gothic;
  font-weight: 400;
  font-size: 16px;
}

.homepage_form button {
  width: 35%;
  padding: 2% 1%;
  border: none;
  outline: none;
  font-family: Century Gothic;
  border-radius: 20px;
  font-size: 14px;
  text-align: center;
  font-weight: 600;
  background-color: #47b45f;
  color: white;
}

.homeform_bg {
  position: absolute;
  top: 1px;
  right: 1px;
  width: 41%;
}

.homeform_bg img {
  width: 100%;
  border-radius: 20px;
}

@media screen and (max-width:1500px) {
  .homeform_bg {
    width: 49%;
  }
}

@media screen and (max-width:1024px) {
  .homepage_contact h4 {
    font-size: 37px;
  }
  .homeform_bg {
    width: 70%;
  }
}

@media screen and (max-width:768px) {
  .homepage_contact_cotent  p{
    font-size: 17px;
  }
  .homepage_form{
    position: relative;
    z-index: 44;
  }
  .book_one {
    width: 45%;
  }

  .books_main_div h1 {
    font-size: 30px;
  }

  .books_sub_div {
    width: 90%;
  }

  .books_sub_div h5 {

    font-size: 16px;
  }

  .book_one h6 {
    font-size: 14px;
  }

  .homepage_contact {
    width: 90%;

  }

  .homepage_contact h4 {
    font-size: 32px;
  }

  .homepage_contact_cotent h6 {
    line-height: 21px;
  }
  .homeform_bg{
    width: 65%;
  } 
}

@media screen and (max-width:450px) {
  .book_one {
    width: 70%;
  }

  .books_sub_div {
    justify-content: center;
  }

  .homepage_contact_cotent {
    width: 100%;
  }

  .homepage_contact h4 {
    font-size: 25px ;
  }

  .homeform_bg {
    top: 10px;
    width: 100%;
  }

  .homepage_form {
    position: relative;
    z-index: 111;
    width: 100%;
  }
  .books_main_div h1{
    font-size: 22px;
    line-height: 24px;
  }
  .price_btn {
    font-size: 12px !important;
  }
  .books_sub_div p {
  font-size: 13px;
  }
  .book_one button{
    font-size: 13px;
  }
  .book_one h6{
    font-size: 13px;
  }
  .books_sub_div h5 {
    font-size: 15px;
}
}
