.deleteAccouuntMainDiv {
    margin-top: 20%;
    width: 100%;
    background-image: url('../assets/images/DeleteAccount/Map.png');
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    height: 50vh;
    display: flex;
    justify-content: center;
    /* align-items: center; */
}


.deleteAccountSubDiv {
    width: 70%;
    position: relative;
}

.deleteAccountSubDiv img {
    width: 43%;
    position: absolute;
    top: -179px;
    left: 0px;
    height: 995px;
}

.innerForm {
    z-index: 997;
    position: absolute;
    /* right: -50px; */
    width: 33.7%;
    left: 4.1%;
    margin-top: -4%;
}

.innerForm h1 {
    font-family: sans-serif;
    font-size: 37px;
    line-height: 45.36px;
    font-weight: 700;
    color: white;
}

.innerForm p {
    font-family: sans-serif;
    font-size: 14px;
    line-height: 14.66px;
    font-weight: 400;
    color: rgba(255, 255, 255, 0.751);
}

.formStart {
    margin-top: 10%;
}

.deleteAccountInputDiv {
    /* background-color: blue; */
    margin-bottom: 50%;
    width: 100%;
}

.deleteAccountInputDiv label {
    font-family: sans-serif;
    font-size: 18px;
    letter-spacing: 3%;
    line-height: 22px;
    font-weight: 400;
    color: rgba(255, 255, 255, 0.751);
    margin-bottom: 10px;
}

.deleteAccountInputDiv input {
    width: 95%;
    background-color: #47B45F;
    border: none;
    outline: none;
    color: rgba(255, 255, 255, 0.751);
    margin: 0;
    padding: 2% 0;
    font-size: 21px;
    line-height: 26px;
    font-weight: 600;
}

.deleteAccountInputDiv ::placeholder {
    color: rgba(255, 255, 255, 0.751);
}

.innerForm button {
    background-color: white;
    padding: 4%;
    width: 38%;
    float: right;
    margin-top: 23%;
    border-radius: 50px;
    border: none;
    color: rgba(71, 180, 95, 1);
    outline: none;
    margin-right: 3%;
    font-size: 12.6px;
    font-weight: 500;
}

.deleteAccountAddressDiv {
    background-color: white;
    width: 100%;
    display: flex;
    justify-content: flex-end;
    margin-top: 6%;
    padding-bottom: 6%;
    position: relative;


}

.deleteAccountAddressSubDiv {
    width: 50%;
    margin-top: -5%;
    z-index: 5;
    display: flex;
    /* justify-content: space-between; */
    align-content: center;
    gap: 20px;
}

.deleteAccountIcons {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 26%;
}

.deleteAccountIcons img {
    width: 54px;
    height: 54px;
}

.deleteAccountIcons h4 {
    font-weight: 400;
    font-size: 18px;
    line-height: 21.42px;
}

.deleteAccountIcons h5 {
    font-weight: 400;
    font-size: 18px;
    line-height: 21.42px;
}
/* media query */

@media (max-width:1880px) {

    /* .deleteAccountSubDiv img {
            height: 925px;

      */
    /* .innerForm button {

        margin-top: 45%;
    } */

    .deleteAccountAddressDiv {
        padding-bottom: 26%;
    }

    .vocalCalledInDeleteAccount {
        margin-top: -24%;
        position: relative;
    }
}

@media (max-width:1600px) {
    .deleteAccountInputDiv {
        margin-bottom: 40%;
    }

    .deleteAccountIcons h5 {
        text-align: center;
    }
}

@media (max-width:1200px) {
    .deleteAccouuntMainDiv {
        height: 30vh;
    }

    .deleteAccountSubDiv img {
        width: 53%;
        position: absolute;
        top: -169px;
        left: -20px;
        height: 695px;
    }

    .innerForm {
        width: 40%;
    }
}

@media (max-width:1024px) {
    .deleteAccountAddressSubDiv {
        margin-top: 5%;
    }

    .deleteAccountAddressDiv {
        justify-content: center;
        padding-bottom: 19%;
        margin-top: 52%;
    }

    .deleteAccountAddressSubDiv {
        width: 70%;
        justify-content: center;
    }

    .deleteAccouuntMainDiv {
        display: flex;
        justify-content: flex-end;
    }

    .deleteAccountSubDiv {
        width: 69%;
        margin-top: 35%;
    }

    .deleteAccountSubDiv img {
        width: 60%;
    }

    .innerForm {
        width: 50%;
        left: 2.1%;
        margin-top: -16%;
    }

    .deleteAccountSubDiv img {
        height: 658px;
    }
}

@media (max-width:768px) {
    .deleteAccountSubDiv img {
        height: 608px;
        width: 70%;
    }

    .innerForm h1 {
        font-size: 27px;
    }

    .innerForm {
        width: 60%;
    }

    .deleteAccountAddressSubDiv {
        width: 100%;
    }

    .deleteAccouuntMainDiv {
        margin-top: 5%;
    }
}

@media (max-width:500px) {
    .deleteAccountSubDiv img {
        width: 80%;
        height: 422px;
    }

    .deleteAccountSubDiv {
        width: 75%;
        margin-top: 55%;
    }

    .innerForm {
        width: 67%;
        margin-top: -35%;
        left: 0;
    }

    .deleteAccountIcons h4 {
        font-size: 16px;
    }

    .deleteAccountIcons h5 {
        font-size: 14px;
    }

    .innerForm h1 {
        font-size: 20px;
        line-height: 15px;
    }

    .deleteAccountInputDiv input {
        font-size: 16px;
    }

    .deleteAccountAddressDiv {
        margin-top: 60%;
    }

    .deleteAccountInputDiv label {
        font-size: 15px;
    }
}

@media (max-width:400px) {
    .deleteAccountAddressDiv {
        margin-top: 70%;
    }

    .deleteAccountInputDiv input {
        font-size: 15px;
    }

    .innerForm p {
        font-size: 12px;
    }
}