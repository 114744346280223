.products {
  margin-left: 5%;
}

.productImage {
  height: 360px;
  width: 90%;
  position: relative;
  left: 5%;
  top: 0px;
  border-radius: 20px;
}
.bookText {
  width: 70%;
  margin-top: 20%;
}
#productText {
  font-size: 17px;
  line-height: 23px;
}
.productPrice {
  font-size: 55px;
  font-family: "GothicBold";
  line-height: 70px;
  letter-spacing: 0px;
  text-align: left;
  color: #484848;
}

@media only screen and (max-width: 600px) {
  .c11 {
    position: relative;
    top: -190px;
  }
  .c22 {
    position: relative;
    left: 12%;
    top: -260px;
  }
  #productHead {
    margin-bottom: 45%;
    font-size: 15px;
  }
  #productText {
    font-size: 17px;
    line-height: 23px;
    padding: auto;
    margin: auto;
    position: relative;
    left: 0;
  }
  #p-button {
    position: relative;
    margin-bottom: 2%;
    margin-top: -15px !important;
  }
  .productPrice {
    font-size: 35px;
    font-family: "GothicBold";
    line-height: 50px;
  }
  .products {
    display: flex;
    flex-direction: column-reverse;
  }
}

