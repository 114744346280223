.contact_form_main_div {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  padding: 8% 0;
}
.Contact_form_content label {
  margin-bottom: 2%;
  font-family: "CenturyGothic";
  font-weight: 600;
  font-size: 22px;
  color: #484848;
}
.contactBox {
  background: linear-gradient(
    261deg,
    #e3e3e3 -9.07%,
    rgba(227, 227, 227, 0) 86.9%
  );

  font-family: "CenturyGothic";
  font-size: 16px;
  text-align: left;

  position: relative;
}
.boxContent {
  font-family: "GothicBold";
  font-size: 48px;
  margin-top: 4%;
  margin-left: 5%;
  line-height: 72px;
}
.table-image {
  width: 275px;
  left: 10px !important;
}
.Contact_form_content {
  width: 40%;
  background-color: #fff;
  padding: 3%;
  border-radius: 20px;
}
.contact_page_left_div {
  width: 35%;
}
.contact-text {
  margin-left: 6%;
  font-size: 20px;
  margin-bottom: 8%;
  
}
.info {
  display: flex;
  flex-direction: column;
  margin-left: 5%;
  line-height: 40px;
}
.recapta_main_div{
    z-index: 50;
  position: relative;
  padding: 12px 6%;
}
.recapta_main_div_bottom{
  display: none;
}
@media screen and (max-width:1024px){
  .Contact_form_content label{
    font-size: 16px;
  }
  .boxContent {
    font-size: 32px;
  }
  .contact-text {
  font-size: 18px;
  }
  .info img{
    width: 30px;
    height:30px;
  }
  .form-control {
  font-size: 0.8rem;
  }
}
@media screen and (max-width:768px){
  .recapta_main_div{
    display: none;

  }
  .recapta_main_div_bottom{
    display: block;
  }
  .contact_form_main_div{
    flex-direction: column;
  }
  .contact_page_left_div{
    width: 100%;
    margin-bottom: 5%;
  } 
  .contact-text{
    margin-bottom: 4%;
    width: 90%;
  }

  .Contact_form_content{
    width: 90%;
  }
}
@media screen and (max-width:450px){
  .boxContent{
    font-size: 24px;
  } 
  .contact-text {
    font-size: 16px;
}
}
