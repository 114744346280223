/* Importing all CSS files */
@import url("./Header.css");
@import url("./Navbar.css");
@import url("./Slider.css");
@import url("./JoinSection.css");
@import url("./VocalSection.css");
@import url("./Footer.css");
@import url("./Register.css");
@import url("./FormTopBar.css");
@import url("./OrderDetails.css");
@import url("./PaymentsAfterLogin.css");
@import url("./Products.css");
@import url("./About.css");
@import url("./ContactUs.css");
@import url("./Testimonial.css");
@import url("./Blog.css");
@import url("./BlogDetails.css");
@import url("./DeleteAccount.css");

@font-face {
  font-family: "CenturyGothic";
  src: url("../assets/fonts/Century Gothic.ttf");
}

@font-face {
  font-family: "GothicBold";
  src: url("../assets/fonts/GOTHICB.TTF");
}

/* Defaults */
* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

.App {
  overflow-y: hidden;
}

/* Primary Variables */
:root {
  --primary: "green";
}