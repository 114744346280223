.review_div {
  background-color: #f4fbf6;
  padding: 6% 4% 10% 4% !important;
  position: relative;
  z-index: 999;
}
.review_1 {
  background-color: white;
  width: 96%;
  border-radius: 20px;
}
.review_1 p {
  font-size: 16px;
  line-height: 22.07px;
  font-family: Century Gothic;
  font-weight: 400;
  text-align: left !important;
  color: #484848;
}
.slick-center #custom-card {
  background-color: #4bc04e;
  color: white !important;
}
.slick-center #custom-card p {
  color: white !important;
}
.slick-center #custom-card h5 {
  color: white !important;
}

.bg-white {
  background-color: #f4fbf6 !important;
}
.review_1 h5 {
  font-weight: 600;
  font-family: Century Gothic;
  color: #484848;
  font-size: 18px;
  line-height: 24.52px;
}
.review_div h1 {
  text-align: center;
  padding: 2% 0;
  font-family: Century Gothic;
  font-size: 36px;
  line-height: 44.14px;
  font-weight: 600;
  color: #484848;
}
.slick-next:before {
  color: #6fbe44 !important;
  top: 160px;
  position: absolute;
  right: 50%;
  font-size: 50px;
}
.slick-next {
  right: 40%;
}
.slick-prev {
  left: 40%;
}
.slick-prev:before {
  color: red !important;
  font-size: 50px;
  color: #6fbe44 !important;
  top: 160px;
  position: absolute;
  left: 50%;
}
@media only screen and (max-width:768px){
  .review_1 p{
    font-size: 14px;
  }
  .review_1 h5 {
  font-size: 16px;
  }
  .review_div h1{
    font-size: 33px;
  }
  .slick-prev:before {
    top: 130px;
    font-size: 35px;
  }
  .slick-next:before {
    font-size: 35px;
    top: 130px;

  }
}
@media only screen and (max-width:450px){
  .slick-next {
    right: 30%;
  }
  .slick-prev {
    left: 30%;
  }
  .review_div{
    padding: 6% 4% 20% 4% !important;
  }
}